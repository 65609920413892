import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

import NotForProfitBanner from "@images/hero-bg4.jpeg"

const NotForProfit = () => (
	<Layout>
		<SEO
			title="Not For Profit Boulder County | Not For Profit Provider | NeighborhoodIT"
			description="NeighborhoodIT serves as a not for profit provider who offers enterprise-class technology services to Boulder County small businesses."
		/>
		<div
			id="hero-banner"
			style={{
				backgroundImage: `url(${NotForProfitBanner})`,
				backgroundPosition: `bottom`,
			}}
		>
			<div className="hero-text">
				<div className="container">
					<div className="row justify-content-between align-items-center text-white">
						<div className="col-12">
							<h1>Let the pros do it!</h1>
							<p>
								Our career I.T. professionals provide services members of our
								community on a non-for-profit basis.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="page-content">
			<div className="container">
				<div className="row justify-content-between align-items-center">
					<div className="col-12">
						<h1>Not-for-Profit</h1>
						<p className="font-weight-bold">
							For many small and midsize organizations struggling to manage
							their IT internally, technology is often more of a burden than a
							benefit.
						</p>
						<p>
							A NeighborhoodIT professional will come to you home and address
							any and all of your technology needs at a fraction of the price
							charged by the usual retail squads. Our clients are allowed to
							purchase hardware and software directly from our network of
							suppliers, skipping the retail mark-up entirely. Since we don't
							profit from product sales, we can replace your current
							subscription-based software (i.e. antivirus), saving you money
							year after year.
						</p>
						<p>
							Most of our residential services are $100 or less. Contact us
							today and experience what it's like to work with a true
							professional!
						</p>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default NotForProfit
